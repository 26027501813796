import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as CatUtil from '../../Utils/CatUtil';
import {showAdminDownloadExcelModal} from '../../Components/AdminDownloadExcelModal';
import AdminSelectUserCustomWidget from '../../Generators/AdminResource/AdminSelectUserCustomWidget';
import {
  transformProjection,
  transformQuery,
  transformQueryText,
} from '../../Utils/MoraleExportUtil';

const EXPORT_COLLECTION = 'morale';
const EXPORT_TITLE_DEFAULT = '倫理風紀匯出';
const EXPORT_FIELDS = [
  {key: '__serial_number', name: '流水號'},
  {key: 'case_number_prefix', name: '案號前綴'},
  {key: 'case_number', name: '案號'},
  {key: '__prosecutors', name: '申訴人（移送機關團體）（立案調查）'},
  {key: '__defendants', name: '被申訴律師（受調查對象）'},
  {key: 'investigator_type', name: '調查小組類別'},
  {key: '__investigators', name: '調查小組'},
  {key: 'occur_date', name: '違紀行為時點（粗估供參）'},
  {key: 'start_date', name: '受理日期'},
  {key: '__labels', name: '案件類型'},
  {key: 'status', name: '案件辦理進度'},
  {key: 'status_note', name: '進度備註'},
  {key: '__resolution_sessions', name: '決議屆（會）次'},
  {key: '__results_tba', name: '本會處理結果'},
  {key: 'end_date', name: '結果通知日期'},
  {key: '__results_punishment', name: '移付懲戒結果'},
  {key: 'occur_content', name: '申訴內容'},
  {key: 'resolution_result_tba', name: '本會決定結果'},
  {key: 'resolution_law', name: '所涉法規'},
  {key: 'resolution_gist_internal', name: '決定要旨：完整版'},
  {key: 'resolution_gist_external', name: '決定要旨：去識別化版'},
  {key: 'note', name: '備註'},
];

function AdminMoraleExports(props) {
  const [users] = useOutlet('users');
  const [config] = useOutlet('config');

  const moraleStatusCategory = config.morale_status_category || [];
  const cats = CatUtil.getFlattenCategories('moraleCategories');

  const rjsfProps = {
    widgets: {
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
    },
  };

  return (
    <Wrapper>
      <GenericForm
        instance={null}
        rjsfProps={rjsfProps}
        schema={{
          title: '',
          type: 'object',
          required: [],
          properties: {
            title: {
              type: 'string',
              title: '報表名稱',
              default: EXPORT_TITLE_DEFAULT,
            },
            case_number_prefix: {
              type: 'string',
              title: '案號前綴',
              enum: ['北律倫調字', '北律倫特調字'],
            },
            case_number: {
              type: 'integer',
              title: '案號',
            },
            labels: {
              type: 'array',
              title: '案件類型',
              uniqueItems: true,
              default: [],
              items: {
                type: 'string',
                enum: [...cats.map((cat) => cat.name)],
                enumNames: [...cats.map((cat) => cat.display)],
              },
            },
            start_date_start: {
              type: 'string',
              title: '受理日期（起）',
              format: 'date',
            },
            start_date_end: {
              type: 'string',
              title: '受理日期（迄）',
              format: 'date',
            },
            investigator_report_deadline_start: {
              type: 'string',
              title: '調查小組應提出調查報告期限（起）',
              format: 'date',
            },
            investigator_report_deadline_end: {
              type: 'string',
              title: '調查小組應提出調查報告期限（迄）',
              format: 'date',
            },
            investigator_report_deadline_extend_start: {
              type: 'string',
              title: '延長後之調查報告提出期限（起）',
              format: 'date',
            },
            investigator_report_deadline_extend_end: {
              type: 'string',
              title: '延長後之調查報告提出期限（迄）',
              format: 'date',
            },
            occur_date_start: {
              type: 'string',
              title: '違紀行為時點（粗估供參）（起）',
              format: 'date',
            },
            occur_date_end: {
              type: 'string',
              title: '違紀行為時點（粗估供參）（迄）',
              format: 'date',
            },
            end_date_start: {
              type: 'string',
              title: '結果通知日期（起）',
              format: 'date',
            },
            end_date_end: {
              type: 'string',
              title: '結果通知日期（迄）',
              format: 'date',
            },
            status: {
              type: 'array',
              title: '案件辦理進度',
              items: {
                type: 'string',
                enum: [...moraleStatusCategory],
              },
              uniqueItems: true,
            },
            status_note: {
              type: 'string',
              title: '進度備註',
            },
            investigator_type: {
              title: '調查小組類別',
              type: 'string',
              enum: ['調查小組', '特別調查小組'],
            },
            prosecutor_name: {
              type: 'string',
              title: '申訴人（移送機關團體）（立案調查）',
            },
            defendant_name: {
              type: 'string',
              title: '被申訴律師（受調查對象）',
            },
            investigator_a: {
              type: 'string',
              title: '調查小組：召集人',
            },
            investigator_b: {
              type: 'string',
              title: '調查小組：調查委員',
            },
            result_tba: {
              type: 'string',
              title: '處理結果',
              enum: [
                '簽結',
                '不予處分',
                '命其注意',
                '勸告',
                '告誡',
                '移付懲戒',
              ],
            },
            note: {
              type: 'string',
              title: '備註',
            },
            selectAllFields: {
              type: 'boolean',
              title: '匯出欄位全選',
              enum: [true, false],
              enumNames: ['是', '否'],
              default: true,
            },
          },
          dependencies: {
            selectAllFields: {
              oneOf: [
                {
                  required: ['fields'],
                  properties: {
                    selectAllFields: {enum: [false]},
                    fields: {
                      type: 'array',
                      title: '匯出欄位選擇',
                      items: {
                        type: 'string',
                        enum: EXPORT_FIELDS.map((field) => field.key),
                        enumNames: EXPORT_FIELDS.map((field) => field.name),
                      },
                      uniqueItems: true,
                    },
                  },
                },
              ],
            },
          },
        }}
        uiSchema={{
          investigator_a: {
            'ui:widget': 'admin-select-user-custom-widget',
          },
          investigator_b: {
            'ui:widget': 'admin-select-user-custom-widget',
          },

          selectAllFields: {
            'ui:widget': 'radio',
          },
          fields: {
            'ui:widget': 'checkboxes',
          },
          status_note: {
            'ui:widget': 'textarea',
          },
          note: {
            'ui:widget': 'textarea',
          },
        }}
        onChange={(formData) => {
          if (!formData.start_date_start) {
            delete formData.start_date_start;
          }

          if (!formData.start_date_end) {
            delete formData.start_date_end;
          }

          if (!formData.investigator_report_deadline_start) {
            delete formData.investigator_report_deadline_start;
          }

          if (!formData.investigator_report_deadline_end) {
            delete formData.investigator_report_deadline_end;
          }

          if (!formData.investigator_report_deadline_extend_start) {
            delete formData.investigator_report_deadline_extend_start;
          }

          if (!formData.investigator_report_deadline_extend_end) {
            delete formData.investigator_report_deadline_extend_end;
          }

          if (!formData.occur_date_start) {
            delete formData.occur_date_start;
          }

          if (!formData.occur_date_end) {
            delete formData.occur_date_end;
          }

          if (!formData.end_date_start) {
            delete formData.end_date_start;
          }

          if (!formData.end_date_end) {
            delete formData.end_date_end;
          }
        }}
        onSubmit={(formData) => {
          const {selectAllFields, fields} = formData;
          const selectedFields = selectAllFields
            ? EXPORT_FIELDS
            : EXPORT_FIELDS.filter(
                (obj) => fields.findIndex((key) => key === obj.key) > -1,
              );

          const queryText = transformQueryText(formData, users);
          const query = transformQuery(formData, users);
          const projection = transformProjection(selectedFields);

          let excelProps = {};

          excelProps = {
            collection: EXPORT_COLLECTION,
            filename: `${formData.title || EXPORT_TITLE_DEFAULT}.xlsx`,
            query,
            projection,
            recordsToAoa: async (records) => {
              return [
                [`${formData.title}${queryText}`],
                [...selectedFields.map((field) => field.name)],
                ...records
                  .sort((a, b) => b.case_number - a.case_number)
                  .map((record, index) => {
                    const returnValues = selectedFields.map((field) => {
                      if (field.key === '__serial_number') {
                        return `${index + 1}`;
                      }

                      if (field.key === '__prosecutors') {
                        const p = record['prosecutors']?.map((prosecutor) => {
                          const targetUser = users.find(
                            (user) => user.owner === prosecutor,
                          );

                          return targetUser ? targetUser.name : prosecutor;
                        });

                        const pn = record['prosecutors_normal']?.map(
                          (prosecutor) => {
                            return prosecutor.name;
                          },
                        );

                        let text = ``;

                        for (let i = 0; i < (p || []).length; i++) {
                          if (i + 1 === (p || []).length) {
                            text += `${p[i]}`;
                          } else {
                            text += `${p[i]}\n`;
                          }
                        }

                        for (let i = 0; i < (pn || []).length; i++) {
                          if (i + 1 === (pn || []).length) {
                            text += `${pn[i]}`;
                          } else {
                            text += `${pn[i]}\n`;
                          }
                        }

                        return [text];
                      }

                      if (field.key === '__defendants') {
                        const d = record['defendants']?.map((defendant) => {
                          const targetUser = users.find(
                            (user) => user.owner === defendant,
                          );

                          return targetUser ? targetUser.name : defendant;
                        });

                        const dn = record['defendants_normal']?.map(
                          (defendant) => {
                            return defendant.name;
                          },
                        );

                        let text = ``;

                        for (let i = 0; i < (d || []).length; i++) {
                          if (i + 1 === (d || []).length) {
                            text += `${d[i]}`;
                          } else {
                            text += `${d[i]}\n`;
                          }
                        }

                        for (let i = 0; i < (dn || []).length; i++) {
                          if (i + 1 === (dn || []).length) {
                            text += `${dn[i]}`;
                          } else {
                            text += `${dn[i]}\n`;
                          }
                        }

                        return [text];
                      }

                      if (field.key === '__labels') {
                        const arrayData = record['labels']?.map(
                          (label) => label,
                        );

                        let text = ``;
                        for (let i = 0; i < (arrayData || []).length; i++) {
                          if (i + 1 === (arrayData || []).length) {
                            text += `${arrayData[i]}`;
                          } else {
                            text += `${arrayData[i]}\n`;
                          }
                        }
                        return [text];
                      }

                      if (field.key === '__resolution_sessions') {
                        return record['resolution_sessions']?.map(
                          (session) =>
                            `${session.morale ? '倫理風紀委員會' : ''}${
                              session.morale_note
                                ? `-${session.morale_note}`
                                : ''
                            }
                          ${session.supervisior ? '理監事聯席會' : ''}${
                              session.supervisior_note
                                ? `-${session.supervisior_note}`
                                : ''
                            }`,
                        );
                      }

                      if (field.key === '__results_tba') {
                        const arrayData = record['results_tba']?.map((r) => {
                          const targetUser = users.find(
                            (user) => user.owner === r.user,
                          );
                          return `${targetUser ? targetUser.name : r.user}:${
                            r.result || ''
                          }/${r.note || ''}`;
                        });

                        let text = ``;
                        for (let i = 0; i < (arrayData || []).length; i++) {
                          if (i + 1 === (arrayData || []).length) {
                            text += `${arrayData[i]}`;
                          } else {
                            text += `${arrayData[i]}\n`;
                          }
                        }

                        return [text];
                      }

                      if (field.key === '__results_punishment') {
                        return record['results_punishment']?.map((r) => {
                          const targetUser = users.find(
                            (user) => user.owner === r.user,
                          );

                          return `${targetUser ? targetUser.name : r.user}:${
                            r.result || ''
                          }${r.confirm ? '已確定' : '未確定'}/${r.note || ''}`;
                        });
                      }

                      if (field.key === '__investigators') {
                        const arrayData = record['investigators']?.map((r) => {
                          const targetUser = users.find(
                            (user) => user.owner === r.user,
                          );

                          return `${r.responsibility}-${
                            targetUser ? targetUser.name : r.user
                          }`;
                        });

                        let text = ``;
                        for (let i = 0; i < (arrayData || []).length; i++) {
                          if (i + 1 === (arrayData || []).length) {
                            text += `${arrayData[i]}`;
                          } else {
                            text += `${arrayData[i]}\n`;
                          }
                        }

                        return [text];
                      }

                      return record[field.key];
                    });

                    return [...returnValues];
                  }),
              ];
            },
            updateWorkSheet: (ws) => {
              // s = start, r = row, c = col, e = end
              const merge = [{s: {r: 0, c: 0}, e: {r: 0, c: 21}}];
              ws['!merges'] = merge;

              const a1_rows = {...ws['A1']}.v.split('\n').length;
              ws['!rows'] = [{hpx: 22 * a1_rows}];
              ws['A1'].s = {
                alignment: {
                  wrapText: true,
                  vertical: 'center',
                },
              };

              //status_note
              const l_rows = Object.keys(ws).filter((k) => k.indexOf('L') > -1);

              for (let i = 0; i < l_rows.length; i++) {
                const rows = {...ws[l_rows[i]]}.v.split('\n').length;
                if (rows > 1) {
                  ws[l_rows[i]].s = {
                    alignment: {
                      wrapText: true,
                      vertical: 'center',
                    },
                  };
                }
              }

              //note
              const v_rows = Object.keys(ws).filter((v) => v.indexOf('V') > -1);
              for (let i = 0; i < v_rows.length; i++) {
                const rows = {...ws[v_rows[i]]}.v.split('\n').length;
                if (rows > 1) {
                  ws[v_rows[i]].s = {
                    alignment: {
                      wrapText: true,
                      vertical: 'center',
                    },
                  };
                }
              }
            },
          };

          showAdminDownloadExcelModal({...excelProps});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AdminMoraleExports;
