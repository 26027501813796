export function transformQuery(formData, users) {
  const {
    start_date_start,
    start_date_end,
    investigator_report_deadline_start,
    investigator_report_deadline_end,
    investigator_report_deadline_extend_start,
    investigator_report_deadline_extend_end,
    occur_date_start,
    occur_date_end,
    end_date_start,
    end_date_end,
    case_number_prefix,
    case_number,
    labels,
    status,
    status_note,
    prosecutor_name,
    defendant_name,
    investigator_type,
    investigator_a,
    investigator_b,
    result_tba,
  } = formData;

  let query = {};

  if (!!start_date_start) {
    query['start_date'] = {$gte: start_date_start};
  }

  if (!!start_date_end) {
    if (query['start_date']) {
      query['start_date'] = {
        ...query.start_date,
        $lt: start_date_end,
      };
    } else {
      query['start_date'] = {$lt: start_date_end};
    }
  }

  if (!!investigator_report_deadline_start) {
    query['investigator_report_deadline'] = {
      $gte: investigator_report_deadline_start,
    };
  }

  if (!!investigator_report_deadline_end) {
    if (query['investigator_report_deadline']) {
      query['investigator_report_deadline'] = {
        ...query['investigator_report_deadline'],
        $lt: investigator_report_deadline_end,
      };
    } else {
      query['investigator_report_deadline'] = {
        $lt: investigator_report_deadline_end,
      };
    }
  }

  if (!!investigator_report_deadline_extend_start) {
    query['investigator_report_deadline_extend'] = {
      $gte: investigator_report_deadline_extend_start,
    };
  }

  if (!!investigator_report_deadline_extend_end) {
    if (query['investigator_report_deadline_extend']) {
      query['investigator_report_deadline_extend'] = {
        ...query['investigator_report_deadline_extend'],
        $lt: investigator_report_deadline_extend_end,
      };
    } else {
      query['investigator_report_deadline_extend'] = {
        $lt: investigator_report_deadline_extend_end,
      };
    }
  }

  if (!!occur_date_start) {
    query['occur_date'] = {$gte: occur_date_start};
  }

  if (!!occur_date_end) {
    if (query['occur_date']) {
      query['occur_date'] = {
        ...query['occur_date'],
        $lt: occur_date_end,
      };
    } else {
      query['occur_date'] = {
        $lt: occur_date_end,
      };
    }
  }

  if (!!end_date_start) {
    query['end_date'] = {
      $gte: end_date_start,
    };
  }

  if (!!end_date_end) {
    if (query['end_date']) {
      query['end_date'] = {
        ...query['end_date'],
        $lt: end_date_end,
      };
    } else {
      query['end_date'] = {
        $lt: end_date_end,
      };
    }
  }

  if (!!case_number_prefix) {
    query['case_number_prefix'] = {$regex: case_number_prefix};
  }

  if (!!case_number) {
    query['case_number'] = case_number;
  }

  if (labels.length > 0) {
    query['labels'] = {$in: labels};
  }

  if (!!prosecutor_name) {
    const targetUsers = users.filter(
      (u) => u.name.indexOf(prosecutor_name) > -1,
    );

    if (targetUsers.length === 0) {
      query['prosecutors_normal'] = {
        $elemMatch: {name: {$regex: prosecutor_name}},
      };
    } else {
      query = {
        ...query,
        $or: [
          {prosecutors: {$in: targetUsers.map((u) => u.owner)}},
          {
            prosecutors_normal: {
              $elemMatch: {name: {$regex: prosecutor_name}},
            },
          },
        ],
      };
    }
  }

  if (!!defendant_name) {
    const targetUsers = users.filter(
      (u) => u.name.indexOf(defendant_name) > -1,
    );

    if (targetUsers.length === 0) {
      query['defendants_normal'] = {
        $elemMatch: {name: {$regex: defendant_name}},
      };
    } else {
      query = {
        ...query,
        $or: [
          {defendants: {$in: targetUsers.map((u) => u.owner)}},
          {
            defendants_normal: {
              $elemMatch: {name: {$regex: defendant_name}},
            },
          },
        ],
      };
    }
  }

  if (!!investigator_a) {
    query['investigators'] = {
      $elemMatch: {user: investigator_a, responsibility: '召集人'},
    };
  }

  if (!!investigator_b) {
    if (query.investigators) {
      delete query.investigators;

      query.$or = [
        {
          investigators: {
            $elemMatch: {
              user: investigator_a,
              responsibility: '召集人',
            },
          },
        },
        {
          investigators: {
            $elemMatch: {
              user: investigator_b,
              responsibility: '調查委員',
            },
          },
        },
      ];
    } else {
      query['investigators'] = {
        $elemMatch: {user: investigator_b, responsibility: '調查委員'},
      };
    }
  }

  if (!!status) {
    query['status'] = {$in: status};
  }

  if (!!status_note) {
    query['status_note'] = {$regex: status_note};
  }

  if (!!investigator_type) {
    query['investigator_type'] = investigator_type;
  }

  if (!!result_tba) {
    query['results_tba'] = {$elemMatch: {result: result_tba}};
  }

  return query;
}

export function transformQueryText(formData, users) {
  let text = `\n`;

  if (!!formData.case_number_prefix) {
    text += `案號前綴：${formData.case_number_prefix}\n`;
  }

  if (!!formData.case_number) {
    text += `案號：${formData.case_number}\n`;
  }

  if (Array.isArray(formData.labels) && formData.labels.length > 0) {
    let labelsText = ``;

    for (let i = 0; i < formData.labels.length; i++) {
      labelsText += `${formData.labels[i]}${
        i + 1 === formData.labels.length ? '' : '、'
      }`;
    }

    text += `案件類型：${labelsText}\n`;
  }

  if (!!formData.start_date_start) {
    text += `受理日期（起）：${formData.start_date_start}\n`;
  }

  if (!!formData.start_date_end) {
    text += `受理日期（迄）：${formData.start_date_end}\n`;
  }

  if (!!formData.investigator_report_deadline_start) {
    text += `調查小組應提出調查報告期限（起）：${formData.investigator_report_deadline_start}\n`;
  }

  if (!!formData.investigator_report_deadline_end) {
    text += `調查小組應提出調查報告期限（迄）：${formData.investigator_report_deadline_end}\n`;
  }

  if (!!formData.investigator_report_deadline_extend_start) {
    text += `延長後之調查報告提出期限（起）：${formData.investigator_report_deadline_extend_start}\n`;
  }

  if (!!formData.investigator_report_deadline_extend_end) {
    text += `延長後之調查報告提出期限（迄）：${formData.investigator_report_deadline_extend_end}\n`;
  }

  if (!!formData.occur_date_start) {
    text += `違紀行為時點（粗估供參）（起）：${formData.occur_date_start}\n`;
  }

  if (!!formData.occur_date_end) {
    text += `違紀行為時點（粗估供參）（迄）：${formData.occur_date_end}\n`;
  }

  if (!!formData.end_date_start) {
    text += `結果通知日期（起）：${formData.end_date_start}\n`;
  }

  if (!!formData.end_date_end) {
    text += `結果通知日期（迄）：${formData.end_date_end}\n`;
  }

  if (Array.isArray(formData.status) && formData.status.length > 0) {
    let statusText = '';

    for (let i = 0; i < formData.status.length; i++) {
      statusText += `${formData.status[i]}${
        i + 1 === formData.status.length ? '' : '、'
      }`;
    }

    text += `案件辦理進度：${statusText}\n`;
  }

  if (!!formData.status_note) {
    text += `進度備註：${formData.status_note.replace(/\n/g, ' ')}\n`;
  }

  if (!!formData.investigator_type) {
    text += `調查小組類別：${formData.investigator_type}\n`;
  }

  if (!!formData.prosecutor_name) {
    text += `申訴人（移送機關團體）（立案調查）：${formData.prosecutor_name}\n`;
  }

  if (!!formData.defendant_name) {
    text += `被申訴律師（受調查對象）：${formData.defendant_name}\n`;
  }

  if (!!formData.investigator_a) {
    const targetUser = users.find(
      (user) => user.owner === formData.investigator_a,
    );
    text += `調查小組：召集人：${targetUser ? targetUser.name : ''}\n`;
  }

  if (!!formData.investigator_b) {
    const targetUser = users.find(
      (user) => user.owner === formData.investigator_b,
    );
    text += `調查小組：調查委員：${targetUser ? targetUser.name : ''}\n`;
  }

  if (!!formData.result_tba) {
    text += `處理結果：${formData.result_tba}\n`;
  }

  if (!!formData.note) {
    text += `備註：${formData.note.replace(/\n/g, ' ')}\n`;
  }

  return text;
}

export function transformProjection(selectedFields) {
  const projection = {};

  for (let i = 0; i < selectedFields.length; i++) {
    if (selectedFields[i].key.indexOf('__') === -1) {
      projection[selectedFields[i].key] = 1;
    }

    if (selectedFields[i].key.indexOf('__prosecutors') === -1) {
      projection['prosecutors'] = 1;
      projection['prosecutors_normal'] = 1;
    }

    if (selectedFields[i].key.indexOf('__defendants') === -1) {
      projection['defendants'] = 1;
      projection['defendants_normal'] = 1;
    }

    if (selectedFields[i].key.indexOf('__labels') === -1) {
      projection['labels'] = 1;
    }

    if (selectedFields[i].key.indexOf('__resolution_sessions') === -1) {
      projection['resolution_sessions'] = 1;
    }

    if (selectedFields[i].key.indexOf('__results_tba') === -1) {
      projection['results_tba'] = 1;
    }

    if (selectedFields[i].key.indexOf('__results_punishment') === -1) {
      projection['results_punishment'] = 1;
    }

    if (selectedFields[i].key.indexOf('__investigators') === -1) {
      projection['investigators'] = 1;
    }
  }

  return projection;
}
